import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import mapImg from "../../Assets/map-ipad.jpg";
import Tilt from "react-parallax-tilt";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={6} className="home-about-description1">
            <h1 style={{ fontSize: "2.6em" }}>
              WHAT <span className="purple"> WE </span> DO.
            </h1>
            <p className="home-about-body">
              Our mission is simple yet powerful – to{" "}
              <i>
                <b className="purple"> revolutionize dispatch services </b>
              </i>{" "}
              by leveraging cutting-edge technology, industry best practices,
              and a dedicated{" "}
              <i>
                <b className="purple"> team of professionals. </b>
              </i>{" "}
              We strive to be at the{" "}
              <i>
                <b className="purple"> forefront of innovation, </b>
              </i>{" "}
              ensuring that our{" "}
              <i>
                <b className="purple">
                  {" "}
                  clients can rely on us to deliver their goods with precision,
                  speed, and utmost care.
                </b>
              </i>{" "}
            </p>
          </Col>
          <Col md={6} className="myAvtar">
            <Tilt>
              <img loading="lazy" src={mapImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
