import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import founderImg from "../../Assets/founder.jpeg";
import Tilt from "react-parallax-tilt";

function About4() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={6} className="home-about-description1">
            <h2 style={{ fontSize: "1.45em" }}>
              If you are looking for{" "}
              <b className="purple"> a reliable dispatch company </b> that
              offers seamless logistics solutions, look no further.{" "}
              <b className="purple">
                {" "}
                Contact us today to discuss your requirements,{" "}
              </b>{" "}
              and let us pave the way to a{" "}
              <b className="purple">
                {" "}
                more efficient and successful future for your business.{" "}
              </b>{" "}
            </h2>
            <br />
            <br />
            <h4 style={{ fontSize: "1.5em", fontWeight: "bold" }}>
              
            </h4>{" "}
            <b className="purple"> BRIJ.NG Team </b> <br />
            <b>#TheFutureOfDeliveries</b>
          </Col>
          <Col md={6} className="myAvtar">
            <Tilt>
              {/* <img loading="lazy" src={founderImg} className="image" alt="avatar" /> */}
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default About4;
