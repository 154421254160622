import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";
import SuperApp from "./SuperApp";
import SuperApp2 from "./SuperApp2";

function SuperAppHome() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
      </Container>
      <SuperApp2 />
      <SuperApp />
    </section>
  );
}

export default SuperAppHome;
