import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import userDashboard from "../../Assets/user_dashboard.png";
import userDashboard1 from "../../Assets/user_carousel2.png";
import Tilt from "react-parallax-tilt";

function SuperApp2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={6} className="myAvtar">
            <Tilt>
              <img
                loading="lazy"
                src={userDashboard}
                className="img-fluid"
                alt="avatar"
              />
            </Tilt>
          </Col>
          <Col md={6} className="home-about-description">
            <Tilt>
              <img
                loading="lazy"
                src={userDashboard1}
                className="img-fluid"
                alt="avatar"
              />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default SuperApp2;
