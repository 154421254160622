import React from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBRadio,
} from "mdb-react-ui-kit";

function Form() {
  return (
    <MDBContainer fluid>
      <MDBRow className="justify-content-center align-items-center mt-20 m-5">
        <MDBCard>
          <MDBCardBody className="px-4">
            <h3 className="fw-bold mb-4 pb-2 pb-md-0 mb-md-5">
              BRIJ.NG PARTNER REGISTRATION
            </h3>

            <MDBRow>
              <MDBCol md="6">
                <MDBInput
                  wrapperClass="mb-4"
                  label="Full Name"
                  size="lg"
                  id="form1"
                  type="text"
                />
              </MDBCol>

              <MDBCol md="6">
                <MDBInput
                  wrapperClass="mb-4"
                  label="Phone Number"
                  size="lg"
                  id="form2"
                  type="text"
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="6">
                <MDBInput
                  wrapperClass="mb-4"
                  label="Email"
                  size="lg"
                  id="form4"
                  type="email"
                />
              </MDBCol>

              <MDBCol md="6">
                <MDBInput
                  wrapperClass="mb-4"
                  label="Do you have a bike | car | bicycle | truck? "
                  size="lg"
                  id="form5"
                  type="rel"
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="6">
                <MDBInput
                  wrapperClass="mb-4"
                  label="Fill in the riders card number (Either for yourself or the rider)"
                  size="lg"
                  id="form4"
                  type="email"
                />
              </MDBCol>

              <MDBCol md="6">
                <h6 className="fw-bold">Kindly state your current location</h6>
                <MDBRadio
                  name="inlineRadio"
                  id="inlineRadio1"
                  value="Company"
                  label="Lagos"
                  inline
                />
                <MDBRadio
                  name="inlineRadio"
                  id="inlineRadio2"
                  value="Rider"
                  label="Other"
                  inline
                />
              </MDBCol>
            </MDBRow>

            <MDBBtn className="mb-4" size="lg">
              Submit
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBRow>
    </MDBContainer>
  );
}

export default Form;
