import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import mockImage from "../../Assets/mock1.png";
import appleLogo from "../../Assets/apple_logo.svg";
import googleLogo from "../../Assets/google_logo.svg";
import Tilt from "react-parallax-tilt";
import TypeMsg from "./TypeMsg";

function SuperApp() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={6} className="home-about-description1">
            <h1 style={{ fontSize: "2.2em" }}>WITH US, DELIVERIES ARE</h1>
            <div style={{ padding: 50, textAlign: "left" }}>
              <TypeMsg />
            </div>
            <h1 style={{ fontSize: "1.2em" }}>DOWNLOAD OUR APP </h1>
            <div>
              <Tilt>
                <a href="https://apps.apple.com/app/brij-ng/id6466821624"><img
                  src={appleLogo}
                  loading="lazy"
                  className="i"
                  alt="avatar"
                /></a>
                {" "}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <a href="https://play.google.com/store/apps/details?id=com.company.brij"><img src={googleLogo} className="img-fluid" alt="avatar" /></a>
              </Tilt>
            </div>
          </Col>
          <Col md={6} className="myAvtar">
            <Tilt>
              <img
                loading="lazy"
                src={mockImage}
                className="img-fluid"
                alt="avatar"
              />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default SuperApp;
