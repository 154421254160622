import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import deliveryMan from "../../Assets/delivery_man_bike.jpg";
import Tilt from "react-parallax-tilt";

function About2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={6} className="home-about-description1">
            <h1 style={{ fontSize: "2.0em" }}>
              {" "}
              <b className="purple">What is Brij? </b>
            </h1>
            <p className="home-about-body">
              As a <b className="purple"> leading dispatch company, </b>
              we offer a comprehensive suite of services{" "}
              <b className="purple">designed to optimize your supply chain </b>
              and streamline your operations.{" "}
              <b className="purple">
                {" "}
                From small businesses to large enterprises,
              </b>
              we cater to a diverse clientele, spanning various industries.
            </p>
          </Col>
          <Col md={6} className="myAvtar">
            <Tilt>
              <img loading="lazy" src={deliveryMan} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default About2;
