import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import deliveryMan from "../../Assets/delivery_guy.jpg";
import Tilt from "react-parallax-tilt";

function Home4() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={6} className="myAvtar">
            <Tilt>
              <img
                loading="lazy"
                src={deliveryMan}
                className="img-fluid"
                alt="avatar"
              />
            </Tilt>
          </Col>
          <Col md={6} className="home-about-description1">
            <h1 style={{ fontSize: "2.2em" }}>
              DO YOU HAVE A BIKE, CAR, BICYCLE, TRUCK?
            </h1>
            <p className="home-about-body">
              If you’re a{" "}
              <i>
                <b className="purple">car, bike, bicyle or truck </b>
              </i>{" "}
              owner and are looking for means{" "}
              <i>
                <b className="purple"> increase your earnings,</b>
              </i>{" "}
              You can join the{" "}
              <i>
                <b className="purple"> BRIJ.NG </b>
              </i>{" "}
              team to double your earnings
            </p>

            <button className="btn-primary">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSclPs7dQSiupCq_eijuDyoNXGkDaI5AIBJfA-Nj_8x1Vnrmag/viewform?usp=sf_link"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                {" "}
                JOIN US!
              </a>{" "}
            </button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home4;
