import React from "react";
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { FaLinkedinIn, } from "react-icons/fa";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          
          <Nav.Link as={Link} to="/privacy-policy" >
                <h3>Privacy Policy</h3>
              </Nav.Link>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} BRIJ. All rights reserved</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/company/brij-worldng/"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.facebook.com/brij.ng"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillFacebook />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://twitter.com/brij_ng/status/1703781313088487758?s=48&t=CyETi0PZCZXC_4acY6tPFw"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillTwitterSquare />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://instagram.com/brij.ng?igshid=OGQ5ZDc2ODk2ZA=="
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
