import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import mapImg from "../../Assets/car-map.jpg";
import Tilt from "react-parallax-tilt";

function About3() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={6} className="myAvtar">
            <Tilt>
              <img loading="lazy" src={mapImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
          <Col md={6} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              {" "}
              <b className="purple">Our Services </b>
            </h1>
            <ul className="home-about-body">
              <li>
                <b className="purple">Expedited Dispatch: </b>
                Need urgent delivery? Our team is adept at handling
                time-sensitive shipments, ensuring your goods reach their
                destination promptly.
              </li>
              <li>
                <b className="purple"> Route Optimization: </b>
                We employ advanced routing algorithms to optimize delivery
                routes, saving you time, money, and fuel while reducing your
                carbon footprint.
              </li>
              <li>
                <b className="purple"> Real-Time Tracking: </b>
                Stay informed throughout the delivery process with our real-time
                tracking system, giving you complete visibility and peace of
                mind.
              </li>
              <li>
                <b className="purple"> Customer Support: </b>
                Our customer support team is available round-the-clock, ready to
                address any queries or concerns, making sure you are always
                supported.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default About3;
