import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import wwImg from "../../Assets/ww-delivery.jpg";
import Tilt from "react-parallax-tilt";

function Home5() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={6} className="home-about-description1">
            <h1 style={{ fontSize: "2.2em" }}>WORLD WIDE DELIVERY</h1>
            <p className="home-about-body">
              We handle{" "}
              <i>
                <b className="purple"> freight services </b>
              </i>{" "}
              on goods by various modes of transportation, including ships,
              airplanes, trucks, trains, and more
            </p>

            <button className="btn-primary">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSclPs7dQSiupCq_eijuDyoNXGkDaI5AIBJfA-Nj_8x1Vnrmag/viewform?usp=sf_link"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                {" "}
                JOIN US!
              </a>{" "}
            </button>
          </Col>
          <Col md={6} className="myAvtar">
            <Tilt>
              <img
                loading="lazy"
                src={wwImg}
                className="img-fluid"
                alt="avatar"
              />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home5;
