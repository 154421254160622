import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import mapImg from "../../Assets/car-map.jpg";
import Tilt from "react-parallax-tilt";
import {

  AiFillTwitterSquare,
  AiFillFacebook, 
  AiFillInstagram,
  
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home3() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={6} className="home-about-description1">
            <h1 style={{ fontSize: "2.6em" }}>
              REAL-TIME <span className="purple"> DELIVERY </span> TRACKING
            </h1>
            <p className="home-about-body">
              We've a system which enable users see riders on a{" "}
              <b className="purple"> map </b> radar from point of pick, down to
              delivery 🤷‍♂️ &nbsp; Which shows
              <i>
                <b className="purple"> transparency </b>
              </i>
            </p>
          </Col>
          <Col md={6} className="myAvtar">
            <Tilt>
              <img loading="lazy" src={mapImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="home-about-social">
            <h1>REACH US ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with us
            </p>
            <ul className="home-about-social-links">
            
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/brij-worldng/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/brij.ng"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/brij_ng/status/1703781313088487758?s=48&t=CyETi0PZCZXC_4acY6tPFw"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillTwitterSquare />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://instagram.com/brij.ng?igshid=OGQ5ZDc2ODk2ZA=="
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home3;
