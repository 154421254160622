import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import About2 from "./About2";
import About3 from "./About3";
import About4 from "./About4";

function About() {
  return (
    <section>
      <Container className="about-section-bg" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                <b className="purple">About Us </b>
              </h1>

              <h1 className="text-name">
                we understand the critical role that logistics and dispatch play
                in the success of your business. With years of experience in the
                industry, we have honed our{" "}
                <b className="purple">
                  expertise to provide seamless and efficient dispatch services{" "}
                </b>
                tailored to meet your unique needs.
              </h1>
            </Col>
          </Row>
        </Container>
      </Container>
      <About2 />
      <About3 />
      <About4 />
    </section>
  );
}

export default About;
